@font-face {
  font-family: 'Arbbalck';
  src: url('../assets/fonts/ArbFONTS-DINNextLTArabic-Black-4.ttf') format('truetype');
}

@font-face {
  font-family: 'Arbbold';
  src: url('../assets/fonts/ArbFONTS-DINNextLTArabic-Bold-4.ttf') format('truetype');
}

@font-face {
  font-family: 'Arbheavy';
  src: url('../assets/fonts/ArbFONTS-DINNextLTArabic-Heavy2-2.ttf') format('truetype');
}

@font-face {
  font-family: 'Arblight';
  src: url('../assets/fonts/ArbFONTS-DINNEXTLTARABIC-LIGHT-2-2.ttf') format('truetype');
}

@font-face {
  font-family: 'Arbmed';
  src: url('../assets/fonts/ArbFONTS-DINNextLTArabic-Medium-4.ttf') format('truetype');
}

@font-face {
  font-family: 'Arbreg';
  src: url('../assets/fonts/ArbFONTS-DINNextLTArabic-Regular-4.ttf') format('truetype');
}

@font-face {
  font-family: 'Arbutllight';
  src: url('../assets/fonts/ArbFONTS-DINNextLTArabic-UltraLight-4.ttf') format('truetype');
}

* {
  padding: 0;
  margin: 0;
  font-family: 'Arbreg', sans-serif;
}

body {
  direction: rtl;
  overflow-x: hidden;
}

a {
  text-decoration: none;
  color: #fff;
}

.headerLinksMainPage {
  padding: 0px 20px;
  letter-spacing: 1.2px;
  cursor: pointer;
}

.headerLinksMainPageSep {
  color: #fff;
}

@keyframes timeLineGameMinutes {
  0% {
    transform: translateX(-100%);
  }

  50% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-100%);
  }
}